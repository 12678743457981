.wrapper {
    position: relative;

    h2 {
        margin-bottom: 40px;
        text-align: center;
    }

    > div {
        max-width: 595px;
        min-height: 900px;
        margin: 0 auto;
    }
}
