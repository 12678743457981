body {
    :global {
        .ant-dropdown.ant-dropdown-placement-bottomRight {
            z-index: 200;
            //top: 319px !important;
            pointer-events: none;
        }
    }
}

.table {
    position: relative;

    .tableFolderInput {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--blue-headline);
        background-color: transparent !important;
        border-color: transparent !important;
        outline-color: transparent !important;
        box-shadow: none !important;
    }

    :global {
        .ant-table {
            background-color: transparent;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

            .ant-table-thead {
                > tr > th {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    background-color: var(--white);
                    border-bottom-color: var(--light-blue-grey);
                    font-weight: 600;
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                    text-transform: uppercase;
                    color: var(--text-blue);

                    &:first-of-type {
                        padding-left: 36px;
                    }

                    &:last-of-type {
                        padding-right: 30px;
                        text-align: right;
                    }

                    &.ant-table-selection-column {
                        min-width: 50px;
                    }
                }

                tr:first-child th:first-child {
                    border-top-left-radius: 8px;
                }

                tr:first-child th:last-child {
                    border-top-right-radius: 8px;
                }
            }

            .ant-table-tbody {
                > tr {
                    > td {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        background-color: var(--white);
                        border-bottom-color: var(--light-blue-grey);
                        font-size: 1.4rem;
                        line-height: 1.7rem;
                        color: var(--grey);

                        &:first-of-type {
                            padding-left: 50px;
                            font-weight: 500;
                            font-size: 1.6rem;
                            line-height: 1.9rem;
                            color: var(--blue-headline);
                        }

                        &:last-of-type {
                            //padding-right: 30px;
                            text-align: right;
                        }

                        &.ant-table-selection-column {
                            min-width: 50px;
                        }

                        &.ant-table-cell-with-append {
                            .anticon,
                            .ant-badge-status {
                                position: absolute;
                                left: 25px;
                                top: 50%;
                                padding: 10px;
                                transform: translateY(-50%);
                                font-size: 11px;

                                .ant-badge-status-text {
                                    display: none;
                                }
                            }

                            .anticon.anticon-menu {
                                display: none;
                                z-index: 101;
                                left: 5px;
                                right: auto;

                                &:before {
                                    content: none;
                                }

                                svg {
                                    display: inline-block;
                                }
                            }
                        }

                        &.ant-table-row-editable {
                            background-color: #F6F7F9;

                            + td {
                                background-color: #F6F7F9;

                                + td {
                                    background-color: #F6F7F9;

                                    + td {
                                        background-color: #F6F7F9;
                                    }
                                }
                            }

                            .ant-row.ant-form-item {
                                margin: 0;
                            }

                            .ant-form-item-explain {
                                position: absolute;
                                left: 6px;
                                bottom: -20px;
                                font-size: 13px;
                                line-height: 1.1;
                            }
                        }

                        .ant-table-row-indent {
                            padding-left: 15px !important;
                        }
                    }

                    &:last-of-type {
                        > td {
                            border-bottom: none;

                            &:first-child {
                                border-bottom-left-radius: 8px;
                            }

                            &:last-child {
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }

                    &.ant-table-row {
                        &:hover {
                            .anticon.anticon-menu {
                                display: inline-block;
                            }
                        }
                    }

                    &.ant-table-row-selected {
                        td {
                            background-color: var(--lightest-blue-l);
                        }
                    }

                    // 1st level row
                    &.ant-table-row-with-children {
                        > td {
                            position: relative;
                        }

                        > .ant-table-cell-with-append {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 20px;
                            color: var(--blue-headline);

                            > span {
                                display: flex;

                                > span:last-of-type {
                                    margin-left: 15px;
                                }
                            }

                            .ant-table-row-indent {
                                display: none;
                            }

                            .ant-badge:not(.ant-badge-status) {
                                order: 10;
                            }

                            .anticon,
                            .ant-badge-status {
                                z-index: 99;
                            }
                        }
                    }

                    // Nested rows
                    &.ant-table-row-level-1:not(:last-of-type) {
                        position: relative;

                        > td {
                            padding-top: 10px;
                            padding-bottom: 10px;

                            &:first-of-type {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    bottom: -2px;
                                    left: 0;
                                    width: 65px;
                                    background-color: #fff;
                                }
                            }
                        }

                        &:hover {
                            > td:first-of-type {
                                &:before {
                                    z-index: -2;
                                }
                            }
                        }
                    }

                    // Row opened list
                    &.ant-table-row.ant-table-row-nested {
                        + .ant-table-row:not(.ant-table-row-nested) {
                            //z-index: 9;
                            //position: relative;
                            //transform: scale(1);
                            //box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);

                            //> td {
                            //    border-top-color: var(--light-blue-grey);
                            //
                            //    &:first-child {
                            //        //border-top-left-radius: 8px;
                            //    }
                            //
                            //    &:last-child {
                            //        //border-top-right-radius: 8px;
                            //    }
                            //}
                        }
                    }
                }
            }

            + .ant-table-pagination {
                margin-top: 20px;
                margin-bottom: 0;

                .ant-pagination-item-active,
                .ant-pagination-item:focus,
                .ant-pagination-item:hover {
                    border-color: var(--blue-main);

                    a {
                        color: var(--blue-main);
                    }
                }

                .ant-pagination-prev:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
                .ant-pagination-next:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
                .ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
                .ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
                    color: var(--blue-main);
                    border-color: var(--blue-main);
                }
            }

            .ant-table-column-sorter-up.active,
            .ant-table-column-sorter-down.active {
                color: var(--blue-main);
            }
        }

        .column-with-search-bar {
            //padding-left: 0;

            .ant-table-filter-column-title {
                padding-left: 10px;
            }

            .ant-table-filter-trigger-container {
                background: transparent;
                cursor: auto;

                svg {
                    display: none;
                }
            }
        }
    }
}

.tableSearchWrapper {
    position: relative;
    top: -58px;
    left:  -160px;
    width: 480px;
    height: 43px;
    display: flex;
    align-items: center;
    pointer-events: auto;

    input {
        background-color: #F4F6FD;
        color: var(--grey);
    }

    > button {
        position: absolute;
        right: 5px;
        width: 20px;
        height: 20px;
        padding: 0;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;

        span {
            vertical-align: text-top;
            font-size: 12px;
            color: var(--grey);
        }
    }

    :global {
        .ant-input-affix-wrapper {
            width: 100%;
            height: 36px;
            border: none;
            background-color: #F4F6FD;
            border-radius: 8px;
            color: var(--grey);
        }
    }
}

.statusMarker {
    color: #602BF5;
}

.groupActionWrapper {
    display: flex;
    flex-direction: column;
}

.groupActionButtons {
    display: flex;

    > button,
    > a {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.headButton {
    position: relative;

    > button,
    > a {
        position: absolute;
        top: -91px;
        right: 0;
    }
}

.dropDown {
    border: 1px solid var(--light-blue-grey);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    pointer-events: auto;

    li {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 19px;
        color: var(--dark-grey);

        > a {
            padding: 5px 12px !important;
            color: var(--dark-grey) !important;

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    :global {
        .ant-dropdown-menu-item {
            // white-space: normal;
        }
    }
}

.withFilterCol {
    :global {
        .ant-table-filter-trigger-container {
            right: 40%;
        }
    }
}

.modal {
    position: relative;
    text-align: center;

    :global {
        .ant-modal-footer {
            text-align: center;
        }
    }
}
