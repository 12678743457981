.tabs {
    position: relative;

    :global {
        .ant-tabs-nav {
            margin-bottom: 40px !important;

            &:before {
                height: 2px;
                border: none !important;
                background-color: var(--lightest-blue);
            }
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            position: absolute;
            top: 0;
            background-color: transparent;
        }

        .ant-tabs-tab {
            margin-right: 0;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 1.77;
            color: var(--blue-main);
            border: 2px solid transparent;
            border-bottom: none;
            transition: all 0.2s ease-in-out;

            &:focus,
            &:active,
            &:hover {
                color: var(--blue-headline);
            }

            .ant-tabs-tab-btn a {
                &:focus,
                &:active,
                &:hover {
                    color: var(--blue-headline);
                }
            }

            &.ant-tabs-tab-active {
                background-color: var(--common-bg);
                border-color: var(--lightest-blue);
                border-radius: 8px 8px 0 0;

                .ant-tabs-tab-btn a {
                    color: var(--blue-headline);
                    font-weight: bold;
                }
            }
        }

        .ant-badge {
            > sup {
                margin-left: 6px;
            }
        }
    }
}
