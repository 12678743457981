@import './variables';

.briefcase {
    main > h1 {
        margin-top: 35px;
        margin-bottom: 35px;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        color: var(--blue-headline);
    }

    .ant-tabs {
        margin-bottom: 40px;
        overflow: visible;
    }

    .ant-btn {
        font-weight: 600;
    }
}

.flow-contract {
    background-color: var(--common-contract-bg);
}

.logout {
    main {
        text-align: center;

        .ant-spin {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.thank-you-page,
.error-page {
    > .container {
        h1 {
            margin: 70px 0 30px;
            color: var(--blue-headline);
            text-align: center;
            font-size: 5.6rem;
            line-height: 1.2;
            font-weight: 700;
        }

        h3 {
            max-width: 620px;
            margin: 0 auto 35px;
            color: var(--blue-headline);
            text-align: center;
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 1.56;
        }

        nav {
            margin: 35px auto;
            text-align: center;

            > a,
            > button {
                height: 50px;
                padding: 16px 40px !important;
                line-height: 1;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        @media screen and (max-width: $tablet) {
            h1, h3, nav {
                max-width: 100%;
            }

            nav {
                display: flex;
                flex-direction: column;

                > a,
                > button {
                    &:not(:last-child) {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.product {
    .ant-tooltip {
        .ant-tooltip-arrow-content,
        .ant-tooltip-inner {
            background-color: #A087F1;
        }
    }

    .ant-dropdown-menu-submenu {
        width: 100%;
    }
}
