.wrapper {
    position: relative;
    text-align: right;
}

.userNav {
    //position: relative;

    a {
        &.userNavCTA {
            padding: 13px 28px;
            border: 2px solid var(--base-black);
            border-radius: 4px;
            font-weight: 600;

            &:before {
                top: -30px;
            }
        }

        &.active {
            font-weight: bold;
        }

        > span {
            margin-left: 2px;
            margin-right: -2px;
        }
    }
}

.userNavDropdown {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: var(--base-black);
}
