@import '../../styled/variables';

:global {
    // Doc Preview styling
    :root {
        --bleeding: 0.5cm;
        --margin: 0.5cm;
    }

    @page {
        size: A4;
        margin: 0;
    }

    .page {
        display: inline-block;
        position: relative;
        height: 297mm;
        width: 210mm;
        margin: 2em auto 0 auto;
        padding: calc(var(--bleeding) + var(--margin));
        box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
        background: white;
        font-family: Verdana, Arial, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        overflow: hidden;
        user-select: none;

        p, ol, ul, pre {
            margin: 0;
            font-size: initial;
            line-height: inherit;
            font-weight: initial;
            word-break: break-word;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    .page-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 210mm;
        padding: 0 calc(var(--bleeding) + var(--margin));
        color: rgba(0, 0, 0, 0.85);
        font-size: 13px;
        font-family: Verdana, Arial, sans-serif;
    }

    @media print {
        .page {
            margin: 0;
            overflow: hidden;
        }
    }
}

.wrapper {
    z-index: 9;
    position: relative;
    min-height: 100vh;

    @media (min-height: 950px) and (min-width: 1280px) {
        position: -webkit-sticky;
        position: sticky;
        top: var(--header-height);
        max-width: 794px;
        height: 900px;
    }

    @media screen and (min-width: 551px) and (max-width: 1300px) {
        max-width: 500px;
        margin: 0 auto;
    }

    // like PDF styling...
    br {display: inline-block; width: 100%; height: 24px;}
    p, ol, ul, pre, h1, h2, h3, h4, h5, h6 {margin: 0; padding: 0}
    h1, h2, h3, h4, h5, h6 {font-weight: 600}
    img {vertical-align: middle}
    hr {display: none; opacity: 0; page-break-after: always}
    table {width: 100% !important; max-width: 100% !important; border-collapse: collapse; border-spacing: 0px; table-layout: fixed}
    table, th, td {text-align: left; padding: 4px; border: 1px solid white}
    table th {text-align: center}
    ol, ul {padding-left: 1.5em}
    ol > li, ul > li {list-style-type: none}
    ul > li::before {content: '\2022'}
    li::before {display: inline-block;white-space: nowrap;width: 1.2em}
    ol li {counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; counter-increment: list-0}
    ol li:before {content: counter(list-0, decimal) '. '}

    :global {
        .ant-spin-blur {
            opacity: 0.5;
        }

        li:not(.ql-direction-rtl)::before {margin-left: -1.5em;margin-right: 0.3em;text-align: right}
        li.ql-direction-rtl::before {margin-left: 0.3em;margin-right: -1.5em}
        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {padding-left: 1.5em}
        ol li.ql-direction-rtl,
        ul li.ql-direction-rtl {padding-right: 1.5em}
        ol li.ql-indent-1 {counter-increment: list-1}
        ol li.ql-indent-1:before {content: counter(list-1, lower-alpha) '. '}
        ol li.ql-indent-1 {counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9}
        ol li.ql-indent-2 {counter-increment: list-2}
        ol li.ql-indent-2:before {content: counter(list-2, lower-roman) '. '}
        ol li.ql-indent-2 {counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9}
        ol li.ql-indent-3 {counter-increment: list-3}
        ol li.ql-indent-3:before {content: counter(list-3, decimal) '. '}
        ol li.ql-indent-3 {counter-reset: list-4 list-5 list-6 list-7 list-8 list-9}
        ol li.ql-indent-4 {counter-increment: list-4}
        ol li.ql-indent-4:before {content: counter(list-4, lower-alpha) '. '}
        ol li.ql-indent-4 {counter-reset: list-5 list-6 list-7 list-8 list-9}
        ol li.ql-indent-5 {counter-increment: list-5}
        ol li.ql-indent-5:before {content: counter(list-5, lower-roman) '. '}
        ol li.ql-indent-5 {counter-reset: list-6 list-7 list-8 list-9}
        ol li.ql-indent-6 {counter-increment: list-6}
        ol li.ql-indent-6:before {content: counter(list-6, decimal) '. '}
        ol li.ql-indent-6 {counter-reset: list-7 list-8 list-9}
        ol li.ql-indent-7 {counter-increment: list-7}
        ol li.ql-indent-7:before {content: counter(list-7, lower-alpha) '. '}
        ol li.ql-indent-7 {counter-reset: list-8 list-9}
        ol li.ql-indent-8 {counter-increment: list-8}
        ol li.ql-indent-8:before {content: counter(list-8, lower-roman) '. '}
        ol li.ql-indent-8 {counter-reset: list-9}
        ol li.ql-indent-9 {counter-increment: list-9}
        ol li.ql-indent-9:before {content: counter(list-9, decimal) '. '}
        .ql-indent-1:not(.ql-direction-rtl) {padding-left: 3em}
        li.ql-indent-1:not(.ql-direction-rtl) {padding-left: 4.5em}
        .ql-indent-1.ql-direction-rtl.ql-align-right {padding-right: 3em}
        li.ql-indent-1.ql-direction-rtl.ql-align-right {padding-right: 4.5em}
        .ql-indent-2:not(.ql-direction-rtl) {padding-left: 6em}
        li.ql-indent-2:not(.ql-direction-rtl) {padding-left: 7.5em}
        .ql-indent-2.ql-direction-rtl.ql-align-right {padding-right: 6em}
        li.ql-indent-2.ql-direction-rtl.ql-align-right {padding-right: 7.5em}
        .ql-indent-3:not(.ql-direction-rtl) {padding-left: 9em}
        li.ql-indent-3:not(.ql-direction-rtl) {padding-left: 10.5em}
        .ql-indent-3.ql-direction-rtl.ql-align-right {padding-right: 9em}
        li.ql-indent-3.ql-direction-rtl.ql-align-right {padding-right: 10.5em}
        .ql-indent-4:not(.ql-direction-rtl) {padding-left: 12em}
        li.ql-indent-4:not(.ql-direction-rtl) {padding-left: 13.5em}
        .ql-indent-4.ql-direction-rtl.ql-align-right {padding-right: 12em}
        li.ql-indent-4.ql-direction-rtl.ql-align-right {padding-right: 13.5em}
        .ql-indent-5:not(.ql-direction-rtl) {padding-left: 15em}
        li.ql-indent-5:not(.ql-direction-rtl) {padding-left: 16.5em}
        .ql-indent-5.ql-direction-rtl.ql-align-right {padding-right: 15em}
        li.ql-indent-5.ql-direction-rtl.ql-align-right {padding-right: 16.5em}
        .ql-indent-6:not(.ql-direction-rtl) {padding-left: 18em}
        li.ql-indent-6:not(.ql-direction-rtl) {padding-left: 19.5em}
        .ql-indent-6.ql-direction-rtl.ql-align-right {padding-right: 18em}
        li.ql-indent-6.ql-direction-rtl.ql-align-right {padding-right: 19.5em}
        .ql-indent-7:not(.ql-direction-rtl) {padding-left: 21em}
        li.ql-indent-7:not(.ql-direction-rtl) {padding-left: 22.5em}
        .ql-indent-7.ql-direction-rtl.ql-align-right {padding-right: 21em}
        li.ql-indent-7.ql-direction-rtl.ql-align-right {padding-right: 22.5em}
        .ql-indent-8:not(.ql-direction-rtl) {padding-left: 24em}
        li.ql-indent-8:not(.ql-direction-rtl) {padding-left: 25.5em}
        .ql-indent-8.ql-direction-rtl.ql-align-right {padding-right: 24em}
        li.ql-indent-8.ql-direction-rtl.ql-align-right {padding-right: 25.5em}
        .ql-indent-9:not(.ql-direction-rtl) {padding-left: 27em}
        li.ql-indent-9:not(.ql-direction-rtl) {padding-left: 28.5em}
        .ql-indent-9.ql-direction-rtl.ql-align-right {padding-right: 27em}
        li.ql-indent-9.ql-direction-rtl.ql-align-right {padding-right: 28.5em}
        .ql-size-small {font-size: 0.75em}
        .ql-size-large {font-size: 1.5em}
        .ql-size-huge {font-size: 2.5em}
        .ql-align-center {text-align: center}
        .ql-align-justify {text-align: justify}
        .ql-align-right {text-align: right}
    }
}

.pageWrapper {
    position: relative;
    transform: scale(0.75) translate(-130px, -225px);

    @media screen and (max-width: $desktop) {
        transform: scale(0.65) translate(-140px, -325px);
    }

    @media screen and (max-width: 550px) {
        position: absolute;
        top: 50%;
        margin: 0 auto;
        transform: scale(0.5) translate(-50%, -50%);
    }
}

.navigator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 0;
    max-width: 595px;
    transform: translate(0px, -300px);

    @media screen and (max-width: 550px) {
        max-width: 100%;
        transform: translate(0px, 650px);
    }
}

.navigatorBtn {
    border-radius: 100% !important;
    border-color: var(--light-blue-grey) !important;
    background-color: var(--light-blue-grey) !important;

    > span {
        font-size: 14px;
    }
}

p.navigatorText {
    margin: 0 12px;
    font-size: 14px;
    line-height: 185%;
    text-align: center;
    color: var(--dark-grey);
}
