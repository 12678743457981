@import 'reset';
@import 'variables';
@import 'pages';

*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    min-height: 100%;
    font-size: 7.7px; // 8px

    @media screen and (min-width: $tablet) {
        font-size: 9px;
    }

    @media screen and (min-width: $desktop) {
        font-size: 10px;
    }
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    min-width: 320px;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    font-family: var(--font_inter);
    font-size: 1.6rem;
    font-weight: 500;
    overflow-x: hidden;

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
        height: auto;
    }
}

dd,
dl,
dt {
    margin: 0;
    padding: 0;
}

sup {
    vertical-align: super;
}

sub {
    vertical-align: sub;
}

a {
    text-decoration: none;
    color: var(--blue-main);
    transition: all 0.25s ease;
}

img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: middle;
}

.hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;

    @media screen and (max-width: $desktop) {
        padding: 0 15px;
    }

    @media screen and (max-width: $tablet) {
        padding: 0 20px;
    }
}

main {
    position: relative;
    flex: 1 0 auto;
    margin-top: 105px !important;
    min-height: 250px;

    @media screen and (max-width: $desktop) {
        margin-top: var(--header-mobile-height) !important;
    }
}

.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 20px 0;
    text-align: center;
    color: #fff;
}

.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        //@include vcenter();
    }
}

.clearfix {
    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
}

.logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 140px;
    height: auto;
    max-height: 45px;

    svg {
        max-width: 100%;
        height: auto;
    }

    @media screen and (max-width: $desktop) {
        width: 110px;
        max-height: 32px;
    }
}

.loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 26px;
    margin: auto;
}

// Drag`n`Drop
.row-dragging,
.short-row-dragging {
    width: 1200px;
    background: #F6F7F9;
    border: none;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}

.row-dragging td,
.short-row-dragging td {
    padding: 10px;
    visibility: hidden;

    &:nth-child(1) {
        width: 700px;
        padding-left: 50px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: var(--blue-headline);

        .ant-badge,
        .anticon:not(.anticon-menu) {
            display: none;
        }

        .anticon-menu {
            z-index: 101;
            position: absolute;
            left: 5px;
            right: auto;
            padding: 10px;
            margin-top: 10px;
            transform: translateY(-50%);
            font-size: 11px;
            cursor: grab;
            color: rgb(153, 153, 153);
        }
    }

    &:nth-child(2) {
        width: 150px;
    }

    &:nth-child(3) {
        width: 220px;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: var(--grey);
    }

    &:nth-child(4) {
        width: 30px;
    }
}

.short-row-dragging td {
    &:nth-child(2) {
        width: 220px;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: var(--grey);
    }

    &:nth-child(3),
    &:nth-child(4) {
        display: none;
    }
}

.row-dragging .drag-visible,
.short-row-dragging .drag-visible {
    visibility: visible;
}

.card {
    display: inline-block;
    width: 450px;
    margin: 0 50px 50px 0;
    padding: 20px 10px;
    vertical-align: top;
    background-color: #f2f2f2;
}

.DraftEditor-root {
    padding: 2rem 5rem;
}

.ant-collapse-content {
    overflow: visible;
}

.react-pdf__Page {
    cursor: default;
    user-select: none;
    pointer-events: none;
}

.ant-table-tbody > tr:not([data-row-key]) {
    td {
        padding: 0;
        border-bottom: none;
    }
}

.ant-table-row-children {
    td:nth-child(2) {
        padding-left: 32px;
    }
}

// Tab
.ant-tabs {
    margin-bottom: 50px;
}

.ant-tabs-tabpane-active {
    button[disabled],
    button[disabled] + span {
        cursor: default;
    }
}

// Global
body {
    .ant-btn {
        padding-left: 25px;
        padding-right: 25px;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        color: var(--blue-main);
        border: 2px solid var(--blue-main);
        border-radius: 8px;

        &.ant-btn-primary {
            color: var(--white);
            background-color: var(--blue-main);

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                color: var(--white);
                background-color: var(--blue-hover);
                border-color: var(--blue-hover);
            }
        }

        &.ant-btn-danger {
            color: var(--white);
            border-color: var(--danger);
            background: linear-gradient(90deg, #FF3C61 0%, #FE7C38 100%);

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                color: var(--white);
                background: linear-gradient(90deg, #DF3555, #fe7c38);
                border-color: #FF3C61;
            }
        }

        &.ant-btn-text {
            padding: 0;
            background-color: transparent;
            border: none;
        }

        &.ant-btn-icon-only {
            width: 31px;
            padding-left: 0;
            padding-right: 0;
            background-color: var(--light-blue-l);
            border-color: var(--light-blue-l);
            border-radius: 8px;
        }

        &.ant-btn-sm {
            padding-left: 14px;
            padding-right: 14px;
        }

        &.ant-btn-dangerous {
            background-color: var(--danger);
            border-color: var(--danger);
        }

        &[disabled],
        &[disabled] + span {
            opacity: 0.6;
        }

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            color: var(--blue-hover);
            background-color: transparent;
            border-color: var(--light-border-blue);
        }
    }

    .ant-spin-dot-item {
        background-color: var(--blue-main);
    }

    .ant-dropdown {
        .ant-dropdown-menu {
            padding: 0;
            border-radius: 8px;
        }

        .ant-dropdown-menu-item {
            overflow: hidden;

            &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:last-child {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }

        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover {
            background-color: var(--light-blue-l);
        }

        &.ant-dropdown-placement-bottomRight:not(.ant-dropdown-hidden) {
            display: flex;

            .ant-table-filter-dropdown {
                .ant-dropdown-menu {
                    width: 250px;
                }
            }

            .ant-dropdown-menu {
                //width: 140px;
                margin-left: auto;

                > li a {
                    width: 100%;
                    font-size: 1.6rem;
                    font-weight: 400;
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                    color: #212934;

                    &:hover {
                        background-color: #f9f9fb;
                    }
                }
            }
        }

        // header menu
        &.ant-dropdown-placement-bottomCenter {
            .ant-dropdown-menu {
                min-width: 200px;
                border-radius: 0;
                border-top: 3px solid #427ed1;
                box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.06);

                a {
                    font-family: Inter, Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border-bottom-color: #e2e2e2;
                    color: #2b2e32;
                    font-size: 16px;

                    &:hover {
                        background-color: #f9f9fb;
                    }
                }
            }
        }
    }

    .ant-tabs-dropdown {
        .ant-btn.ant-btn-text.ant-btn-icon-only {
            display: none;
        }
    }

    .ant-table-filter-dropdown {
        background: transparent;
        box-shadow: none;
        // pointer-events: auto;
    }

    .ant-tooltip-inner {
        padding: 6px 12px;
        font-weight: 500;
        font-size: 12px;
        background-color: var(--blue-headline);
        color: var(--white);
    }

    .ant-modal-mask {
        //z-index: 2200;
        background-color: rgba(22, 21, 49, 0.4);
    }

    .ant-modal-content {
        background-color: var(--white);
        border: 1px solid var(--light-blue);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .ant-modal-close {
            display: none;
        }

        .ant-modal-header,
        .ant-modal-footer,
        .ant-modal-body {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 4.5rem;
            padding-right: 4.5rem;
            border: none;

            > .ant-btn {
                display: inline-flex;
                align-items: center;
                padding-top: 4px !important;
                padding-bottom: 4px !important;
                max-width: 150px;
                height: 40px;
                font-weight: 600;
                font-size: 16px;
                line-height: 40px;
                border-radius: 4px;
            }

            .ant-btn + .ant-btn {
                margin-bottom: 0;
                margin-left: 6px;
            }

            @media screen and (max-width: $tablet) {
                .ant-btn {
                    margin-bottom: 10px;

                    + .ant-btn {
                        margin-left: 10px;
                    }
                }
            }
        }

        .ant-modal-title {
            text-align: center;
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 133%;
            color: #060357;
        }

        .ant-modal-body {
            p {
                font-size: 1.6rem;
                line-height: 1.9rem;
                text-align: center;
                color: #050A1A;
            }
        }
    }

    .ant-modal-centered {
        .ant-modal-header,
        .ant-modal-footer,
        .ant-modal-body {
            text-align: center;
        }
    }

    .ant-modal-terms {
        .ant-modal-header,
        .ant-modal-body,
        .ant-modal-footer {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 4rem;
            padding-right: 4rem;
        }

        .ant-modal-header {
            .ant-modal-title {
                margin-top: 28px;
                font-weight: bold;
                font-size: 3.4rem;
                line-height: 4.1rem;
                text-align: center;
                color: #060357;
            }
        }

        .ant-modal-body {
            p {
                text-align: left;
                font-size: 1.6rem;
                line-height: 162%;
                color: var(--dark-grey);

                span {
                    display: block;
                    margin-bottom: 18px;
                }
            }
        }

        .ant-modal-footer {
            padding-bottom: 24px;

            .ant-btn {
                width: 160px;
                height: 48px;
                justify-content: center;
                border-radius: 8px;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;

                + .ant-btn {
                    margin-left: 12px;
                }

                &[disabled]:hover {
                    background-color: var(--blue-main);
                    border-color: var(--blue-main);
                }

                &[type='button']:not(.ant-btn-primary) {
                    background-color: #D6D6EF;
                    border-color: #D6D6EF;
                    color: var(--blue-main);
                }
            }
        }

        @media screen and (max-width: $tablet) {
            .ant-modal-footer {
                display: flex;
                justify-content: center;

                .ant-btn {
                    width: auto;
                }
            }
        }
    }

    .ant-modal-leave {
        width: 550px !important;

        .ant-modal-header {
            padding-top: 0;

            .ant-modal-title {
                font-size: 24px;
                line-height: 133%;
                text-align: center;
                color: #060357;
            }
        }

        .ant-modal-body {
            padding-bottom: 20px;

            p {
                font-size: 16px;
                line-height: 131%;
                font-weight: normal;
                text-align: center;
                color: #050A1A;
            }
        }

        .ant-modal-footer {
            padding-bottom: 30px;

            .ant-btn {
                width: 120px;
                height: 46px;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

    .ant-form {
        .ant-form-item-label {
            > label {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: var(--gray-tr);

                &.ant-form-item-required {
                    &:before {
                        content: none;
                    }
                }
            }
        }

        .ant-input,
        .ant-input-number,
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 100%;
            height: var(--input-height);
            align-items: center;
            background: var(--white);
            border: 1px solid var(--light-blue);
            border-radius: var(--input-radius);
            font-size: 16px;
            font-weight: 400;
            color: var(--blue-headline);

            &.ant-input-disabled {
                opacity: 0.65;
            }
        }

        .ant-picker {
            border: 1px solid var(--light-blue);
            border-radius: var(--input-radius);

            input {
                font-size: 16px;
                font-weight: 400;
                color: var(--blue-headline);
            }
        }

        .ant-select-selection-placeholder,
        .ant-input:placeholder-shown,
        .ant-picker input:placeholder-shown,
        .ant-input-number-input:placeholder-shown {
            font-size: 16px;
            font-weight: 400;
            color: var(--grey);
            opacity: 1;
        }

        &.ant-select-disabled {
            opacity: 0.65;
        }

        textarea.ant-input {
            height: auto;
        }

        .ant-input-number-input {
            height: 38px;
        }
    }

    .ant-badge {
        font-size: initial;

        .ant-badge-count {
            position: relative;
            display: inline-block;
            vertical-align: text-bottom;
            min-width: 21px;
            height: 21px;
            transform: translate(0, 0);
            transform-origin: 0 0;
            background-color: var(--notification-color);
            font-size: 11px;
            font-weight: 600;
            line-height: 21px;
        }
    }

    .ant-message {
        top: 100px;

        .anticon:not(.anticon-loading) {
            display: none;
        }

        .ant-message-notice-content {
            padding: 9px;
            font-family: Inter, Arial, sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.625;
            border-radius: 4px;
        }

        .error-message .ant-message-notice-content {
            color: rgb(197, 87, 87);
            background-color: #FFF1EE;
            border: 1px solid #C55757;
            border-left: 5px solid #C55757;
        }

        .success-message .ant-message-notice-content {
            color: rgb(69, 150, 106);
            background-color: #F0FFF7;
            border: 1px solid #45966A;
            border-left: 5px solid #45966A;
        }

        @media screen and (max-width: 1200px) {
            top: 80px;
        }
    }
}
