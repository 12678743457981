@import '../../styled/variables';

.footer {
    position: relative;
    padding: 75px 55px 60px; // 30px
    flex-shrink: 0;
    color: #fff;
    background-color: #000;

    :global {
        .ant-form-item {
            position: relative;

            .ant-form-item-explain {
                position: absolute;
                bottom: -25px;
                left: 0;
            }

            &.ant-form-item-has-error input.ant-input {
                background-color: transparent;
            }
        }
    }

    > div {
        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (max-width: $desktop) {
        margin-top: 50px;
        padding: 50px 0;

        .footerNav {
            padding-right: 0;
            padding-left: 20px;
        }

        .footerForm {
            width: 100%;
            flex-wrap: nowrap;
        }

        .footerCopyright {
            margin-top: 30px;
        }
    }

    @media screen and (max-width: $tablet) {
        .footerColumn {
            width: 100%;
        }

        .footerNav {
            padding-right: 0;
            padding-left: 0;

            li {
                flex-basis: 50%;
            }
        }
    }
}

.footerColumn {
    width: 20%;
    justify-self: flex-start;

    &:first-of-type {
        width: 40%;
    }

    h3:not(.footerColumnTitle) {
        margin-top: 0;
        margin-bottom: 2rem;
        font-weight: 700;
        font-size: 22px;
        line-height: 1.91;
        color: #fff;
    }

    p {
        max-width: 80%;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.86;
        color: #bcb8b8;
    }

    @media screen and (max-width: $desktop) {
        p {
            max-width: 100%;
        }
    }

    @media screen and (max-width: $tablet) {
        &:first-of-type {
            width: 100%;
            margin-bottom: 30px;
        }

        &:nth-child(2) {
            margin-bottom: 40px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            width: 50%;
        }
    }
}

.footerColumnTitle {
    position: relative;
    display: block;
    margin: 10px 0;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
}

.footerNav {
    display: flex;
    flex-direction: column;
    padding: 22px 0;

    li {
        flex-basis: 40%;
    }

    a {
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 0.4s ease;

        &:hover {
            color: #8b8a93;
        }
    }
}

.footerCopyright {
    width: 95%;
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: #dedede;
    font-weight: 400;

    > span {
        display: block;
        margin-top: 12px;
    }

    &:before {
        content: '';
        position: relative;
        top: -18px;
        left: 0;
        display: block;
        height: 1px;
        background-color: #374eab;
    }

    @media screen and (max-width: $tablet) {
        text-align: left;
    }
}

.footerSocialList {
    position: relative;
    list-style: none;
    display: flex;
    margin: 18px 0;

    a {
        display: inline-block;
        vertical-align: center;
        margin-right: 10px;

        &:hover {
            svg {
                transform: scale(0.9);
            }
        }

        svg {
            max-width: 27px;
            transition: all 0.1s ease;
        }
    }
}

.footerForm {
    position: relative;
    width: 80%;
    margin-top: 3rem;

    input[type='email'] {
        //width: 100%;
        height: 54px !important;
        padding: 5px 25px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: .75px;
        color: #fff !important;
        background-color: rgb(28, 28, 30) !important;
        border: 1px solid rgb(28, 28, 30) !important;
        border-radius: 8px 0 0 8px !important;
        transition: all .3s ease;
        -webkit-appearance: none;

        &::placeholder {
            color: #4f4f4f;
        }

        &:placeholder-shown {
            color: #4f4f4f;
        }
    }

    button[type='button'] {
        text-align: center;
        text-transform: none;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 1;
        border: 1px solid var(--blue-main);
        background-color: var(--blue-main);
        border-radius: 0 8px 8px 0 !important;
    }
}
