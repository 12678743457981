.wrapper {
    //position: relative;

    :global {
        .ant-steps-item-icon,
        .ant-steps-item-content {
            vertical-align: middle;
        }

        .ant-steps-item-icon {
            width: 36px;
            height: 36px;
            border-width: 2px;
            font-family: var(--font_inter);
        }

        .ant-steps-item-wait {
            .ant-steps-item-container {
                cursor: default;
                pointer-events: none;
            }

            .ant-steps-item-icon {
                border-color: #E2EAF5;

                .ant-steps-icon {
                    color: var(--light-text-blue);
                }
            }
        }

        .ant-steps-item-process {
            .ant-steps-item-icon {
                background-color: var(--blue-main);
                border-color: var(--blue-main);
                font-weight: bold;
                font-size: 16px;
                color: var(--common-bg);
            }

            .ant-steps-item-title {
                font-weight: bold;
            }
        }

        .ant-steps-item-finish {
            .ant-steps-item-icon {
                background-color: var(--blue-main);
                border-color: var(--blue-main);
                color: var(--common-bg);

                .ant-steps-icon {
                    color: var(--common-bg);
                }
            }

            .ant-steps-item-title {
                &:after {
                    background-color: var(--blue-main) !important;
                }
            }
        }

        .ant-steps-item-title {
            font-family: var(--font_inter);
            font-size: 12px;
            line-height: 15px;
            color: var(--blue-headline) !important;

            &:after {
                top: 0;
                bottom: 0;
                height: 2px;
                margin: auto;
                background-color: #E2EAF5 !important;
            }
        }

        .ant-tabs {
            .ant-tabs-nav {
                margin-bottom: 0;

                &:before {
                    right: 8px;
                    border-bottom-color: #D4D3EF;
                }

                .ant-tabs-nav-wrap {
                    .ant-tabs-tab {
                        position: relative;
                        border-radius: 4px 4px 0 0;
                        border-color: #D4D3EF;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--blue-hover);
                        background-color: #D4D3EF;

                        .ant-tabs-tab-btn {
                            font-weight: 600;
                            line-height: 23px;
                            color: var(--blue-hover);

                            > span {
                                display: inline-block;
                                vertical-align: middle;
                                max-width: 200px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                text-shadow: none;
                            }
                        }

                        &.ant-tabs-tab-active {
                            background-color: transparent;
                            border-bottom-color: var(--common-bg);

                            .ant-tabs-tab-btn {
                                color: var(--blue-main);
                            }
                        }
                    }
                }
            }

            .ant-tabs-content-holder {
                border: 1px solid #D4D3EF;
                border-top: none;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;

                .ant-tabs-content {
                    padding: 20px;
                }
            }
        }
    }
}

.header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 480px 1fr;
    padding: 29px 0;

    &:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-color: #fff;
    }
}

.column {
    z-index: 3;
    position: relative;
    text-align: center;

    > button {
        min-width: 150px;
        height: 44px;
    }

    &:first-child {
        justify-self: start;
    }

    &:last-child {
        justify-self: end;
    }
}

.content {
    padding: 40px 0;
    //grid-column: 1 / 4;
}
