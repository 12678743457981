:root {
    // colors
    --white: #FFFFFF;
    --common-bg: #F8FBFF;
    --common-intro-bg: #F8F8FF;
    --common-intro-border: #D4D3EF;
    --common-contract-bg: #F3F3F3;
    --base-black: #050A1A;

    --danger: #ff4d4f;
    --green: #A4D69C;

    --grey: #9F9EA9;
    --dark-grey: #4F4F4F;
    --blue-main: #3331B1;
    --blue-headline: #060357;
    --blue-hover: #19177D;

    --light-blue-grey: #E5E9F4;
    --light-blue-l: #EBEFF7;
    --light-blue: #DFE8F3;
    --lightest-blue: #D3E0F0;
    --lightest-blue-l: #e6f7ff;
    --light-text-blue: #B1C7E2;
    --text-blue: #899AC4;
    --light-border-blue: #BBBBE1;
    --light-back: #F1F0FF;
    --light-added: #E9E9F2;
    --light-border: #EEF3FA;

    --easy: #A087F1;
    --notification-color: #602BF5;

    --gray-tr: #0E0D28;
    --other-arg-back: #FCFCFF;
    --light-border-op: rgba(51, 49, 177, 0.05);
    --shadeow-box-agr:  rgba(0, 0, 0, 0.1);
    --super-light-border: #EDEDED;
    --back-for-icon: #E6E6FF;
    --tab-line: #D3D3D3;
    --pack-icon: #BDBDBD;
    --head-c: #F2F2FB;
    --head-bg: linear-gradient(275.24deg, #F7EDA5 3.7%, rgba(247, 237, 165, 0) 51.29%), linear-gradient(149.41deg, #D1E7FF 16.74%, rgba(209, 231, 255, 0) 87.62%), #C284F0;

    // Fonts
    --font_default: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font_inter: Inter, Arial, sans-serif;
    --font_verdana: Verdana, Arial, sans-serif;

    // Sizes
    --input-height: 40px;
    --input-height-xl: 48px;
    --input-radius: 8px;
    --header-height: 105px;
    --header-mobile-height: 72px;
}

// Media Queries
$mobile: 320px;
$mobile_full: 480px;
$tablet: 768px;
$tablet_full: 992px;
$desktop: 1200px;
